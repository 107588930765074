import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./pages/home";
import Solutions from "./pages/solutions";
import EnterpriseAPIs from "./pages/enterprise-apis";
import PaymentsPage from "./pages/payments/";
import Technology from "./pages/technology";
import Token from "./pages/token";
import About from "./pages/about";
import Careers from "./pages/careers";
import OpsManager from "./pages/careers/ops-manager";
import ProjectManager from "./pages/careers/project-manager";
import FullstackEngineer from "./pages/careers/fullstack-engineer";
import SolidityEngineer from "./pages/careers/solidity-engineer";
import Cookie from "./pages/cookie";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Compliance from "./pages/compliance";
import Complaints from "./pages/complaints";
import Notices from "./pages/notices";
import Fees from "./pages/fees";
import Four0Four from "./pages/404";

import "./App.scss";

const App = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/technology" component={Technology} />
            <Route exact path="/token" component={Token} />
            <Route exact path="/about" component={About} />
            <Route exact path="/careers" component={Careers} />
            <Route exact path="/careers/ops-manager" component={OpsManager} />
            <Route exact path="/careers/project-manager" component={ProjectManager} />
            <Route exact path="/careers/fullstack-engineer" component={FullstackEngineer} />
            <Route exact path="/careers/solidity-engineer" component={SolidityEngineer} />
            <Route exact path="/solutions" component={Solutions} />
            <Route exact path="/enterprise-apis" component={EnterpriseAPIs} />
            <Route exact path="/payments" component={PaymentsPage} />
            <Route exact path="/cookie-policy" component={Cookie} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/terms-of-use" component={Terms} />
            <Route exact path="/compliance" component={Compliance} />
            <Route exact path="/complaints" component={Complaints} />
            <Route exact path="/notices" component={Notices} />
            <Route exact path="/fees" component={Fees} />
            <Route path="*" component={Four0Four} />
        </Switch>
    );
};

export default App;
