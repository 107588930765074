import React from "react";
import ReactMarkdown from "react-markdown";

import Layout from "../../components/Layout/Layout";
import Button from "../../components/Button";
import ScrollInvoker from "../../components/ScrollInvoker";
import Technologies from "../../components/Technologies";
import AnchorMenu from "../../components/AnchorMenu";

import { toAbsoluteUrl } from "../../_helpers/utils";

import styles from "./technology.module.scss";
import content from "./content.json";

const Technology = () => {
    const { hero, technologies, security } = content;
    return (
        <Layout>
            <main className={styles.technology}>
                {/* start hero section */}
                <div className={styles.technology__hero}>
                    <div
                        className={
                            styles.technology__hero_full +
                            " wow fadeInUp"
                        }
                        data-wow-delay=".4s"
                    >
                        <img
                            src={toAbsoluteUrl(`${hero.image}`)}
                        />
                            {hero.button_link && (
                                <Button
                                    variant="primary"
                                    text={hero.button_label}
                                    page={hero.button_link}
                                    external
                                />
                            )}
                            {hero.tertiary_button_link && (
                                <Button
                                    variant="secondary"
                                    text={hero.tertiary_button_label}
                                    page={hero.tertiary_button_link}
                                    external
                                />
                            )}
                    </div>
                    
                    {/* <img
                        className={styles.technology__hero_gradient}
                        alt="gradient"
                        src={toAbsoluteUrl("/gradient.svg")}
                    />
                    <div className={styles.technology__hero_left}>
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">
                            {hero.title}
                        </h1>
                        <div
                            className={
                                styles.technology__hero_left_content +
                                " wow fadeInUp"
                            }
                            data-wow-delay=".4s"
                        >
                            {hero.description && (
                                <ReactMarkdown children={hero.description} />
                            )}
                        </div>
                        {hero.button_link && (
                            <Button
                                variant="primary"
                                text={hero.button_label}
                                page={hero.button_link}
                                external
                            />
                        )}
                        <small>{hero.additional_info}</small>
                        {hero.tertiary_button_link && (
                            <Button
                                variant="primary"
                                text={hero.tertiary_button_label}
                                page={hero.tertiary_button_link}
                                external
                            />
                        )}
                        <ScrollInvoker />
                    </div>
                    <div className={styles.technology__hero_right}>
                        <lottie-player
                            class={styles.technology__hero_right_lottie}
                            src={toAbsoluteUrl(
                                "/animations/technology-infographic/dt/desktop.json"
                            )}
                            background="transparent"
                            speed="1.5"
                            autoplay
                        ></lottie-player>
                        <lottie-player
                            class={styles.technology__hero_right_lottie_mobile}
                            src={toAbsoluteUrl(
                                "/animations/technology-infographic/mb/mb.json"
                            )}
                            background="transparent"
                            speed="1.5"
                            autoplay
                        ></lottie-player>
                    </div> */}
                </div>
                {/* end hero section */}

                {/* start technology section */}
                <AnchorMenu items={technologies} />
                <Technologies technologies={technologies} />
                {/* end technology section */}

                {/* start security section */}
                {/* <div id="security" className={styles.technology__security}>
                    <div className={styles.technology__security_wrapper}>
                        <div className={styles.technology__security_left}>
                            <h1 className="wow fadeInUp" data-wow-delay=".3s">
                                {security.title}
                            </h1>
                            <div className="wow fadeInUp" data-wow-delay=".4s">
                                {security.description && (
                                    <ReactMarkdown
                                        children={security.description}
                                    />
                                )}
                            </div>
                            {security.button_link && (
                                <Button
                                    variant="primary"
                                    text={security.button_label}
                                    page={security.button_link}
                                    external={true}
                                />
                            )}
                        </div>
                        <div className={styles.technology__security_right}>
                            <img
                                alt={security.title}
                                src={toAbsoluteUrl(`${security.image}`)}
                            />
                        </div>
                    </div>
                </div> */}
                {/* end security section */}
            </main>
        </Layout>
    );
};

export default Technology;
