import { toAbsoluteUrl } from "../../../_helpers/utils";

import styles from "./solution-detail.module.scss";
import Button from "../../../components/Button";

const SolutionDetail = ({ info }) => {
    return (
        <section id={info.section_id} className={styles.section}>
            <div className={styles.container}>
                <div className={styles.header_row}>
                    <div className={styles.header_left_col}>
                        <img
                            alt={info.section_title}
                            src={toAbsoluteUrl(info.image)}
                            className="wow rotateIn"
                            data-wow-delay=".3s"
                        />
                    </div>
                    <div className={styles.header_right_col}>
                        <div className={styles.header_right_content}>
                            <h2 className="wow fadeInUp" data-wow-delay=".3s">
                                {info.section_title}
                            </h2>
                            <p className="wow fadeInUp" data-wow-delay=".4s">
                                {info.section_description}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.feature_information}>
                    <div
                        className={styles.feature_title + " wow fadeInUp"}
                        data-wow-delay=".3s"
                    >
                        <h3>Features</h3>
                    </div>
                    <div className={styles.feature_row}>
                        {info.features &&
                            info.features.map((item, i) => (
                                <div key={i} className={styles.feature_col}>
                                    <div className={styles.feature_number}>
                                        <span
                                            className="wow rotateIn"
                                            data-wow-delay=".4s"
                                        >
                                            {i + 1}
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            styles.feature_content +
                                            " wow fadeInUp"
                                        }
                                        data-wow-delay=".5s"
                                    >
                                        <p>{item.content}</p>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <div className={styles.component_information}>
                    <div
                        className={styles.component_title + " wow fadeInUp"}
                        data-wow-delay=".3s"
                    >
                        <h3>Solution Components</h3>
                    </div>
                    <div className={styles.component_row}>
                        {info.components &&
                            info.components.map((item, i) => (
                                <div key={i} className={styles.component_col}>
                                    <div
                                        className={
                                            styles.component_name +
                                            " wow fadeInUp"
                                        }
                                        data-wow-delay=".3s"
                                    >
                                        <h4>{item.title}</h4>
                                    </div>
                                    <div
                                        className={
                                            styles.component_content +
                                            " wow fadeInUp"
                                        }
                                        data-wow-delay=".4s"
                                    >
                                        <p>{item.content}</p>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <div className={styles.button_list}>
                    {info.buttons &&
                        info.buttons.map((item, i) => (
                            <div
                                key={i}
                                className={styles.button_col + " wow fadeInUp"}
                                data-wow-delay=".4s"
                            >
                                {item.is_pdf && <a href={`/pdf/${item.filename}`}>{item.title}</a>}
                                {item.is_button && (
                                    <Button
                                        variant="primary"
                                        text={item.title}
                                        page={item.link}
                                        external
                                    />
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </section>
    );
};

export default SolutionDetail;
