export const feed = [
    {
        title: 'Kina Bank & Everest Network',
        pubDate: '2020-12-02 00:00:00',
        link: 'https://medium.com/about-everest-updates-and-news/kina-bank-everest-network-ccc5722636b6',
        thumbnail: 'https://miro.medium.com/max/1400/1*zkJFR-zAWEGdA-TFZaGE9A.png',
        description: 'Kina Bank Scaling new heights with Everest Network All of PNG will soon have access to anew platform to transfer money overseas and trade in foreign currency, thanks to Kina Bank’s new partnership with Everest.',
    },
    {
        title: 'Q1 and Q2 2020 Founders’ Update',
        pubDate: '2020-08-05 00:00:00',
        link: 'https://medium.com/about-everest-updates-and-news/q1-and-q2-2020-founders-update-3d11b91cea83',
        thumbnail: 'https://miro.medium.com/max/700/0*GQQBtoTK9E-Yt07Q',
        description: 'Visa Everywhere Initiative 2020 Semifinalist Everest was only competitor accepted to two Challenges The videos we presented on stage:',
    },
    {
        title: 'Everest Proposal for The Great Reddit Scaling Bake Off',
        pubDate: '2020-07-29 00:00:00',
        link: 'https://medium.com/about-everest-updates-and-news/everest-proposal-for-the-great-reddit-scaling-bake-off-fdbea658cf7f',
        thumbnail: 'https://miro.medium.com/max/2000/1*pyXKFX2R4przzUB5z_QkSQ.png',
        description: 'In response to this posting: Everest prepared the following proposal — we thought those of you not on Reddit (really? you’re missing out…) might also find it interesting.',
    },
    {
        title: 'Digital Identity with Attestations and Value Transfer Capabilities',
        pubDate: '2020-07-07 00:00:00',
        link: 'https://medium.com/about-everest-updates-and-news/digital-identity-with-attestations-and-value-transfer-capabilities-917361614c08',
        thumbnail: 'https://miro.medium.com/max/600/1*iZrtN6iOt7P12d7SeFNnfw.jpeg',
        description: 'Existing digital identity systems mocked by New Yorker Magazine cartoonist Peter Steiner.',
    },
    {
        title: 'Everest — Indonesia Case Study',
        pubDate: '2020-05-12 00:00:00',
        link: 'https://medium.com/about-everest-updates-and-news/everest-indonesia-case-study-1f63083ee138',
        thumbnail: 'https://miro.medium.com/max/1400/1*v24YDoCmDBRvaR-Eec3K6Q.png',
        description: 'EXCERPT: Everest’s device-free biometric identity platform used … to reduce leakage, biometrically verify users and yield efficiencies in the LPG gas subsidy program.',
    },
    {
        title: 'Austrac registers Everest’s platform…..',
        pubDate: '2019-10-17 00:00:00',
        link: 'https://medium.com/about-everest-updates-and-news/austrac-registers-everests-platform-289c2d336fca',
        thumbnail: 'https://miro.medium.com/max/1400/1*z5sGJuWoz02x3uSBaF4tLg.jpeg',
        description: 'As part of our continued work with the Asian Development Bank to build the Central Bank of Samoa’s regulatory compliance platform, Everest is pleased to announce our registration with DIA and Austrac, the financial regulators of New Zealand and Australia respectively. Most recently, Everest was also registered as a money transfer operator with Austrac',
    },
    {
        title: 'Everest selected for Inclusive Fintech 50',
        pubDate: '2019-06-21 00:00:00',
        link: 'https://medium.com/about-everest-updates-and-news/everest-selected-for-inclusive-fintech-50-ca6ff184cf71',
        thumbnail: 'https://miro.medium.com/max/1400/1*uu_CZus04x-Kph5BUDvJDg.jpeg',
        description: 'Visa, the MetLife Foundation, Accion, and the International Finance Corporation (IFC) of the World Bank Group, founded the Inclusive Fintech 50 to recognize promising early-stage fintech companies that are driving financial inclusion.',
    },
    {
        title: 'Ghosts in the machine: the billion people locked out of the global economy',
        pubDate: '2018-10-13 00:00:00',
        link: 'https://medium.com/everestdotorg/ghosts-in-the-machine-the-billion-people-locked-out-of-the-global-economy-a8e56737eb84',
        thumbnail: 'https://miro.medium.com/max/1000/1*wTI9mEgh8cAi-zw8IGOFfQ.jpeg',
        description: 'Who are you? How would you answer that question if your passport, driving license, social security number, or the deeds to your house had been stripped away?',
    },
    {
        title: 'Everest and 1000 Alternatives Partner to Accelerate Blockchain Innovation in Kenya, Nigeria and Rwanda',
        pubDate: '2018-10-12 00:00:00',
        link: 'https://medium.com/about-everest-updates-and-news/everest-and-1000-alternatives-partner-to-accelerate-blockchain-innovation-in-kenya-nigeria-and-a5461e07e2b4',
        thumbnail: 'https://miro.medium.com/max/1400/1*3c88bIgun794855uf_azYw.jpeg',
        description: 'Partnership Will Facilitate Creation of New Distributed Apps On Top Of The Everest Platform Everest, a biometry-based, value transfer company, and 1000 Alternatives, a leader in social impact development',
    },
    {
        title: 'The digital revolution: Accessing the economy without a smartphone',
        pubDate: '2018-09-20 00:00:00',
        link: 'https://medium.com/everestdotorg/the-digital-revolution-accessing-the-economy-without-a-smartphone-b6de6ad04ac6',
        thumbnail: 'https://miro.medium.com/max/1000/1*6ImLKgzmtYmc24Mkjk6toA.jpeg',
        description: 'How we identify ourselves is changing. Globalization and digitization are designed to make the world smaller. But, this is reliant on owning technology. A smartphone, a computer, an Internet connection.',
    },
    {
        title: 'Everest, ID2020 and the Government of Indonesia (TNP2K Secretariat) Announce Innovative Identity and Blockchain Pilot Solution',
        pubDate: '2018-09-18 00:00:00',
        link: 'https://medium.com/about-everest-updates-and-news/everest-id2020-and-the-government-of-indonesia-tnp2k-secretariat-announce-innovative-identity-c9037efdf0cc',
        thumbnail: 'https://miro.medium.com/max/2000/1*VBFZBOK2Y0aXbTqY07M8Ng.jpeg',
        description: 'Everest, the first platform to combine biometric identity with a digital wallet, recently announced that it is partnering with Indonesia’s TNP2K (National Team for the Acceleration of Poverty Reduction) to conduct a pilot in selected regions to enhance the efficiency, accountability, transparency, and gender inclusivity of the National LPG (Liquefied Petroleum Gas) Subsidy Program.',
    }
]